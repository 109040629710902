<template>
  <Navbar></Navbar>
  <div class="grid m-2">
    <div v-for="app in apps" :key="app.title" class="col-12 md:col-6 lg:col-3">
      <PCard>
        <template #header>
          <img :alt="app.title" :src="app.image" width="100%" height="300px" />
        </template>
        <template #title>
          <div class="text-center">{{ app.title }}</div>
        </template>

        <template #content>
          <p class="m-0 text-center">{{ app.content }}</p>
        </template>

        <template #footer>
          <div class="flex gap-4 mt-1">
            <Button label="Open" class="w-full" as="a" :href="app.url" />
          </div>
        </template>
      </PCard>
    </div>
    <div class="col-12 md:col-6 lg:col-3">
      <PCard class="h-full">
        <template #header> &nbsp; </template>
        <template #title>
          <div class="text-center">Netsuite account</div>
        </template>

        <template #content>
          <p class="m-0 text-center">
            Allocate an account to login into NetSuite
          </p>
        </template>

        <template #footer>
          <div class="flex gap-4 mt-1">
            <NetsuiteAccount />
          </div>
        </template>
      </PCard>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import NetsuiteAccount from "@/components/NetsuiteAccount.vue";
import Navbar from '@wovar/common/components/Navbar.vue'

const apps = ref([
  {
    title: "Verzendcentrale",
    content: "Checking orders, print labels and send packages.",
    image: "/assets/verzendcentrale.png",
    url: `${location.protocol}//vc.${location.host}`,
  },
  {
    title: "Box packing",
    content: "More boxes on the transport belt!",
    image: "/assets/boxpacking.webp",
    url: `${location.protocol}//boxes.${location.host}`,
  },
  /*
  {
    title: "User management",
    content: "Manage users and the apps they have access to.",
    url: `${location.protocol}//users.${location.host}`,
  },
   */
]);
</script>

<style scoped lang="scss"></style>
