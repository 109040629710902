<template>
  <div class="dark-navbar">
    <div class="navbar-content">
      <div class="logo-section">
        <div class="logo-container">
          <a :href="homepageUrl">
            <img
              src="@wovar/common/assets/logo/wovar.png"
              alt="Wovar Logo"
              height="20"
              :class="['logo', darkMode ? 'dark-mode' : 'light-mode']"
            />
          </a>
        </div>
        <div class="divider" v-if="pageTitle"></div>
        <h1 class="page-title">{{ pageTitle }}</h1>
      </div>
      <Button
        label="Logout"
        icon="pi pi-sign-out"
        class="p-button-text p-button-plain"
        @click="showLogoutDialog"
      />
    </div>
  </div>
  <!-- Confirmation Dialog -->
    <Dialog
      v-model:visible="logoutDialogVisible"
      :modal="true"
      header="Confirm Logout"
      :style="{ width: '350px' }"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span>Are you sure you want to logout?</span>
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="hideLogoutDialog"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="handleLogout"
          autofocus
        />
      </template>
    </Dialog>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useAuth } from "@wovar/common/auth/store.js";

const props = defineProps({
  darkMode: {
    type: Boolean,
    default: true
  },
  pageTitle: {
    type: String,
    default: ""
  },
  isNestedPage: {
    type: Boolean,
    default: false
  }
});

const logoutDialogVisible = ref(false);

const showLogoutDialog = () => {
  logoutDialogVisible.value = true;
};

const hideLogoutDialog = () => {
  logoutDialogVisible.value = false;
};

const auth = useAuth();

const handleLogout = () => {
  hideLogoutDialog();
  auth.logoutRedirect()
};

const homepageUrl = computed(() => {
  const port = location.port ? `:${location.port}` : '';
  // if we are in a nested page like Verzendcentrale or Boxpicking, get the base URL
  if(props.isNestedPage){
    // check whether the URL has the subdomain part
    const hostnameParts = location.hostname.split('.');
    if(hostnameParts.length > 2){
      const homepageHostname = hostnameParts.slice(1).join('.');
      return `${location.protocol}//${homepageHostname}${port}`
    }
  }
  // Otherwise use the current URL
  return `${location.protocol}//${location.hostname}${port}`
});
</script>

<style scoped>
.dark-navbar {
  width: 100%;
  background-color: #1a1a1a;
  padding: 0.5rem 1rem;
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-section {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.logo-container {
  display: flex;
  align-items: center;
  height: 30px;
}

.logo {
  display: block;
}

.dark-mode {
  filter: invert(1);
}

.divider {
  width: 1px;
  height: 24px;
  background-color: #ffffff40;
}

.page-title {
  color: #ffffff;
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

:deep(.p-button-text) {
  color: #ffffff !important;
}

:deep(.p-button-text:hover) {
  background-color: #333333 !important;
}

:deep(.p-button:focus) {
  box-shadow: none !important;
}

</style>