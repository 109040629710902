import { acceptHMRUpdate, defineStore, storeToRefs } from "pinia";
import { User, UserManager, Log } from "oidc-client-ts";
import { computed, ref, watch } from "vue";
import { computedAsync, until } from "@vueuse/core";
import { config } from "@wovar/common";

if (process.env.NODE_ENV === "development") {
  Log.setLogger(console);
}

type Config = {
  client_id: string;
  authority: string;
  scopes: string[];
};

export const useAuth = defineStore("auth", () => {
  const user = ref<User>();

  const api_config = computedAsync(async () => {
    const response = await fetch(`${config.apiUrl}/auth/config`);
    if (response.ok) {
      return response.json() as unknown as Config;
    }
    throw new Error("Could not retrieve auth configuration from API.");
  });

  const userManager = computed(() => {
    if (api_config.value) {
      const clientId = api_config.value.client_id;
      const authority = api_config.value.authority;
      const scope = api_config.value.scopes.join(" ");
      const redirectURL = absoluteURL("/auth/callback");
      const mgr = new UserManager({
        authority: authority,
        client_id: clientId,
        redirect_uri: redirectURL.href,
        scope: scope,
        response_type: "code",
      });
      mgr.events.addUserLoaded(async (u) => {
        user.value = u;
      });
      mgr.startSilentRenew();
      return mgr;
    }
    return null;
  });

  const isAuthenticated = computed(() =>
    user.value ? !user.value.expired : false,
  );
  const accessToken = computed(() => user.value?.access_token);
  const profile = computed(() => user.value?.profile);

  const loginRedirect = async () => {
    await until(userManager).toBeTruthy();
    await userManager.value!.signinRedirect();
  };
  const loginCallback = async () => {
    await until(userManager).toBeTruthy();
    await userManager.value!.signinCallback();
  };
  const logoutCallback = async () => {
    await until(userManager).toBeTruthy();
    await userManager.value!.signoutCallback();
  };
  const loginSilent = async () => {
    await until(userManager).toBeTruthy();
    const user = await userManager.value!.signinSilent();
    if (!user) {
      await loginRedirect();
    }
  };
  const logoutRedirect = async () => {
    await until(userManager).toBeTruthy();
    await userManager.value!.signoutRedirect({
      extraQueryParams: {
        // Append some Cognito specify parameters, 
        // see https://docs.aws.amazon.com/cognito/latest/developerguide/logout-endpoint.html
        client_id: userManager.value!.settings.client_id,
        logout_uri: absoluteURL('/auth/logout').href,
      }
    });
  };

  const isTokenSet = new Promise<void>((resolve) => {
    let unwatch = watch(accessToken, (token) => {
      if (token) {
        resolve();
      }
      // This only works when the accessToken is not set immediately, which I assume is the case.
      unwatch();
    });
  });

  return {
    isAuthenticated,
    accessToken,
    isTokenSet,
    profile,
    loginRedirect,
    loginCallback,
    loginSilent,
    logoutRedirect,
    logoutCallback,
  };
});
export const useAuthManager = () => storeToRefs(useAuth());

function absoluteURL(path: string) {
  return new URL(path, window.location.origin);
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuth, import.meta.hot));
}
