<template>
  <Button
    label="Netsuite account"
    class="w-full"
    @click="onclick"
    :loading="busy"
  />
  <Dialog
    v-model:visible="showDialog"
    header="NetSuite account"
    class="min-w-min w-6"
  >
    <div class="field grid">
      <label for="username" class="col-12 mb-2 md:col-2 md:mb-0"
        >Username</label
      >
      <div class="col-12 md:col-10">
        <InputGroup>
          <InputText
            id="username"
            disabled
            type="text"
            size="large"
            :value="username"
          />
          <InputGroupAddon v-if="isClipboardSupported">
            <i
              class="pi pi-clipboard"
              @click="() => copyClipboard(username)"
            ></i>
          </InputGroupAddon>
        </InputGroup>
      </div>
    </div>
    <div class="field grid">
      <label for="password" class="col-12 mb-2 md:col-2 md:mb-0"
        >Password</label
      >
      <div class="col-12 md:col-10">
        <InputGroup>
          <InputText
            id="password"
            type="text"
            size="large"
            disabled
            :value="password"
          />
          <InputGroupAddon v-if="isClipboardSupported">
            <i
              class="pi pi-clipboard"
              @click="() => copyClipboard(password)"
            ></i>
          </InputGroupAddon>
        </InputGroup>
      </div>
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import { ref } from "vue";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import { useToast } from "primevue/usetoast";

const toast = useToast();

import { config } from "@wovar/common";
import { useAuth } from "@wovar/common/auth/store.js";

const authStore = useAuth();

const busy = ref(false);
const showDialog = ref(false);
const username = ref<string>();
const password = ref<string>();

const isClipboardSupported = navigator && "clipboard" in navigator;

const allocateAccount = async () => {
  const url = `${config.apiUrl}/v1/ns/users/wms-account`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authStore.accessToken}`,
    },
  });
  if (response.ok) {
    return response.json();
  }
  if (response.status === 400) {
    throw new NoAccountError("No account available");
  }
};
const onclick = async () => {
  try {
    busy.value = true;
    const account = await allocateAccount();
    username.value = account.username;
    password.value = account.password;
    showDialog.value = true;
  } catch (e) {
    if (e instanceof NoAccountError) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "No account available, contact administrator.",
        life: 7000,
      });
    } else {
      toast.add({
        severity: "error",
        summary: "Error",
        detail:
          "Network error, please try again. If the problem persists please contact your administrator.",
        life: 7000,
      });
    }
    throw e; // for log monitoring
  } finally {
    busy.value = false;
  }
};

const copyClipboard = async (text?: string) => {
  if (text) {
    await navigator.clipboard.writeText(text);
    toast.add({
      severity: "info",
      summary: "Copied",
      detail: "Copied to clipboard",
      life: 3000,
    });
  }
};

class NoAccountError extends Error {}
</script>
